<template>
    <v-card :elevation="0" class="v-card--material mx-auto" color="transparent">
        <v-card
            :elevation="0"
            color="secondary"
            tile
        >
            <v-card-title class="pr-0 relative">
                <span
                    class="text-h6 font-weight-light"
                    v-text="$t('dashboard.tasks.headline')"
                />
                <v-spacer />
                <v-btn @click="$emit('refetch')" icon :height="26" :width="26" class="secondary mr-3 pa-0" :loading="$wait.is('fetch tasks')"><v-icon class="primary--text" v-text="'mdi-refresh'" /></v-btn>
            </v-card-title>
        </v-card>
        <v-card-text class="pt-0">
            <div>
                <v-card flat :elevation="0" color="transparent">
                    <v-card-text class="px-0">
                        <v-text-field
                            color="primary"
                            prepend-icon="add"
                            hide-details
                            @keyup.enter="createTask()"
                            ref="createTaskInput"
                            spellcheck="true"
                            :error-messages="errors.collect('taskName')"
                            data-vv-name="taskName"
                            v-validate="'min:1'"
                            data-vv-validate-on="input|change"
                        >
                            <template v-slot:label>
                                <span
                                    v-html="
                                        $t(
                                            'dashboard.tasks.createTaskInputLabel'
                                        )
                                    "
                                    class="pl-2"
                                ></span>
                            </template>
                        </v-text-field>
                    </v-card-text>
                </v-card>
            </div>
            <v-skeleton-loader
                v-if="$wait.is('fetch tasks')"
                :type="'sentences'"
                tile
                class="mx-auto pt-3"
                height="54"
            ></v-skeleton-loader>
            <div v-else class="mr-n4">
                <span v-if="!todoTasks.length">
                    {{ $t('dashboard.tasks.noTasks') }}
                </span>
                <UserTaskDateGroup
                    :items="todoTasks"
                    v-if="todoTasks.length"
                    :height="'calc(100vh - 322px)'"
                    @close="$emit('close')"
                />
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserTaskDateGroup from './UserTaskDateGroup.vue';

export default {
    name: 'UserTasks',
    components: {
        UserTaskDateGroup,
    },
    $_veeValidate: {
        validator: 'new', // see https://github.com/baianat/vee-validate/issues/1547
    },
    i18n: {
        messages: {
            en: require('@/locales/Dashboard/en.json'),
            de: require('@/locales/Dashboard/de.json'),
        },
    },
    computed: {
        ...mapGetters({
            taskItems: 'dashboard/getContactTask'
        }),
        todoTasks() {
            return this.taskItems || [];
        }
    },
    methods: {
        ...mapActions({
            createContactTask: 'dashboard/createContactTask'
        }),
        createTask() {
            const input = this.$refs.createTaskInput;
            if (input.lazyValue && input.lazyValue.trim()) {
                this.createContactTask({
                    title: input.lazyValue.trim(),
                    type: 'todo',
                });
                input.reset();
                this.$validator.reset();
            }
        }
    }
};
</script>
